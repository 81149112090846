import moment from 'moment-timezone'
import _ from 'lodash'

// utils
import { getSnappedDateMoment } from 'helpers/datetime'
import { getFieldValueByTag } from 'helpers/formResponse'

// constants
import {
  EMISSION_OBSERVATION_ATTRIBUTION_TYPES,
  EMISSION_OBSERVATION_TYPE_ICONS_MAPPING,
} from 'app/MissionControlMethaneSolution/constants/emissionObservation'
import { DATE_UNIT_TYPES } from 'constants/datetime'
import { KNOWN_FIELD_TAGS } from 'constants/formBuilder'

import type {
  AttributionExplanationSuggestion,
  DetectionData,
  DetectionType,
  EmissionObservationData,
} from 'app/MissionControlMethaneSolution/types/detection'
import type { Timezone, UtcISOString } from 'types/datetime'
import type { OpsCommentSettings, SerfReportingSettings } from 'types/common'
import type { IssueReference, VentingEvent } from 'types/graphql'

import { ATTRIBUTION_EXPLANATION_SUGGESTION_TYPE } from '../constants/detection'
import { DETECTION_TYPES } from '../constants/common'

export const getAttributionName = ({
  sourceAttribution,
  site,
  equipmentDescription,
}: Pick<DetectionData, 'sourceAttribution'> &
  Partial<Pick<DetectionData, 'site'>> & {
    equipmentDescription?: string | null
  }): string | null | undefined => {
  if (!sourceAttribution) return ''

  const isAttributedToEquipment =
    sourceAttribution.attributionType ===
    EMISSION_OBSERVATION_ATTRIBUTION_TYPES.EQUIPMENT

  return isAttributedToEquipment ? equipmentDescription : site.name
}

export const isVFBEvent = (
  emissionObservation: EmissionObservationData
): boolean => {
  if (!emissionObservation) return false

  return (
    !!emissionObservation.ventingEventType ||
    _.startsWith(emissionObservation.shortId, 'VFB')
  )
}

export const isCMSEvent = (
  emissionObservation: EmissionObservationData
): boolean => {
  if (!emissionObservation) return false

  return (
    emissionObservation.detectionType === DETECTION_TYPES.DETECTION_TYPE_CMS ||
    _.startsWith(emissionObservation.shortId, 'CMS')
  )
}

const isMatchingDetectionSource = (
  emissionObservation: EmissionObservationData,
  source: string
): boolean => {
  return !!emissionObservation && emissionObservation.detectionSource === source
}

export const isQubeData = (
  emissionObservation: EmissionObservationData
): boolean => {
  return !!(
    isMatchingDetectionSource(emissionObservation, 'Qube') ||
    emissionObservation?.detectionSource?.startsWith('Qube')
  )
}

export const isKuvaData = (
  emissionObservation: EmissionObservationData
): boolean => {
  return isMatchingDetectionSource(emissionObservation, 'Kuva Systems')
}

export const getOldestEmissionObservationDate = (
  timezone: Timezone
): UtcISOString =>
  getSnappedDateMoment({
    baseDatetime: moment.utc().subtract(2, DATE_UNIT_TYPES.years),
    timezone,
    unit: DATE_UNIT_TYPES.years,
  }).toISOString()

export const findExistingIssueByFormId = ({
  issues,
  formId,
}: {
  issues?: IssueReference[] | null
  formId?: string
}) =>
  (issues || []).find(issueReference => {
    const dataCollectionFormReference = _.get(
      issueReference,
      'issue.statesParameter[0].dataCollectionFormReference'
    )
    return dataCollectionFormReference === formId
  })

export const checkCanSubmitSerpReport = ({
  serfReportingSettings,
  issues,
}: {
  serfReportingSettings?: SerfReportingSettings
  issues?: IssueReference[] | null
}) => {
  const { enabled, formId } = serfReportingSettings || {}

  if (enabled && (issues?.length || 0) > 0) {
    // If there is an existing report, don't allow to create a new one
    const existingReport = findExistingIssueByFormId({ issues, formId })
    return !existingReport
  }

  return enabled
}

export const getEmissionObservationIconByType = (
  emissionObservationType: DetectionType
) =>
  _.get(
    EMISSION_OBSERVATION_TYPE_ICONS_MAPPING,
    emissionObservationType,
    'MethaneUnknownIcon'
  )

export const getDisplayedExplanation = (
  emissionObservation: EmissionObservationData
): string | undefined => {
  return (
    emissionObservation.sourceAttribution?.attributionExplanation ??
    (emissionObservation as VentingEvent).description
  )
}

/** Creates a list of suggestions that can be used as a prompt for attributionExplanation */
export const getAttributionExplanationSuggestions = ({
  emissionObservation,
  opsCommentSettings = { enabled: false },
}: {
  emissionObservation: EmissionObservationData
  opsCommentSettings?: OpsCommentSettings
}): AttributionExplanationSuggestion[] => {
  const { issues, description } = emissionObservation

  const suggestionOptions: AttributionExplanationSuggestion[] = []

  // IF it's a VFB event, suggest its description
  if (isVFBEvent(emissionObservation) && !!description) {
    suggestionOptions.push({
      type: ATTRIBUTION_EXPLANATION_SUGGESTION_TYPE.VFB_DESCRIPTION,
      content: description,
    })
  }

  // Iterate through the issues related to the emissionObservation
  issues?.forEach(({ issue }) => {
    if (!issue) return

    const { statesParameter, statesData } = issue

    const { dataCollectionFormReference, dataCollectionFormDetails } =
      _.first(statesParameter) || {}
    const { jsonFormBody } = dataCollectionFormDetails || {}

    const { dataCollectionResponses, dataCollectionFormComplete } =
      _.first(statesData) || {}

    // If:
    // - the form is completed
    // - the form schema is provided
    // - and the Ops Comment feature is enabled
    if (
      dataCollectionFormComplete &&
      jsonFormBody &&
      opsCommentSettings?.enabled &&
      opsCommentSettings?.formId === dataCollectionFormReference
    ) {
      const opsCommentText = getFieldValueByTag({
        dataCollectionResponses,
        tagName: KNOWN_FIELD_TAGS.attribution_hint,
        jsonFormBody,
      })

      // Suggest Ops Comment as an attribution explanation
      if (opsCommentText) {
        suggestionOptions.push({
          type: ATTRIBUTION_EXPLANATION_SUGGESTION_TYPE.OPS_COMMENT,
          content: opsCommentText,
        })
      }
    }
  })

  return suggestionOptions
}
